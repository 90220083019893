"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDirectionName = exports.Direction = void 0;
var Direction;
(function (Direction) {
    Direction[Direction["North"] = 0] = "North";
    Direction[Direction["South"] = 1] = "South";
    Direction[Direction["East"] = 2] = "East";
    Direction[Direction["West"] = 3] = "West";
})(Direction || (exports.Direction = Direction = {}));
function getDirectionName(direction) {
    const directionNames = {
        [Direction.North]: "north",
        [Direction.South]: "south",
        [Direction.East]: "east",
        [Direction.West]: "west"
    };
    return directionNames[direction];
}
exports.getDirectionName = getDirectionName;
