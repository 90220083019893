"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resizeToScreen = void 0;
function resizeToScreen(scene, addListener, maxWidth, maxHeight) {
    const resize = () => {
        scene.setScreenSize(Math.min(maxWidth, document.documentElement.clientWidth), Math.min(maxHeight, document.documentElement.clientHeight));
    };
    if (addListener) {
        window.addEventListener('resize', resize);
    }
    //if the screen size is different than the scale size, resize
    if (window.innerWidth != scene.scale.width || window.innerWidth != scene.scale.height) {
        resize();
    }
}
exports.resizeToScreen = resizeToScreen;
