"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentService = void 0;
// Index for objects created
let object_id_index = 0;
class ComponentService {
    constructor() {
        this.componentsByGameObject = new Map();
        this.queuedForStart = [];
    }
    addComponent(go, component) {
        if (!go.id) {
            go.id = ++object_id_index;
        }
        if (!this.componentsByGameObject.has(go.id)) {
            this.componentsByGameObject.set(go.id, []);
        }
        const list = this.componentsByGameObject.get(go.id);
        list.push(component);
        component.init(go);
        if (component.awake) {
            component.awake();
        }
        if (component.start) {
            this.queuedForStart.push(component);
        }
    }
    findComponent(go, componentType) {
        const components = this.componentsByGameObject.get(go.id);
        if (!components) {
            return null;
        }
        return components.find(component => component instanceof componentType);
    }
    destroyComponents(go) {
        const componentsToDestroy = this.componentsByGameObject.get(go.id);
        if (!componentsToDestroy) {
            return null;
        }
        componentsToDestroy.forEach(component => {
            if (component.destroy) {
                component.destroy();
            }
        });
        this.componentsByGameObject.delete(go.id);
    }
    destroyComponent(go, componentType) {
        var _a, _b;
        const componentsToDestory = this.findComponent(go, componentType);
        if (!componentsToDestory) {
            return null;
        }
        if (componentsToDestory.destroy) {
            componentsToDestory.destroy();
        }
        //find the index of the component to remove from go
        const i = (_a = this.componentsByGameObject.get(go.id)) === null || _a === void 0 ? void 0 : _a.findIndex(c => c instanceof componentType);
        //if we found an index, remove it
        if (i != null) {
            (_b = this.componentsByGameObject.get(go.id)) === null || _b === void 0 ? void 0 : _b.splice(i, 1);
        }
    }
    destroy() {
        const entries = this.componentsByGameObject.entries();
        for (const [, components] of entries) {
            components.forEach(component => {
                if (component.destroy) {
                    component.destroy();
                }
            });
        }
        //create new eempty map
        this.componentsByGameObject = new Map();
    }
    update(dt, t) {
        while (this.queuedForStart.length > 0) {
            const component = this.queuedForStart.shift();
            if (component === null || component === void 0 ? void 0 : component.start) {
                component.start();
            }
        }
        const entries = this.componentsByGameObject.entries();
        //TODO create update list
        for (const [, components] of entries) {
            components.forEach(component => {
                if (component.update) {
                    component.update(dt, t);
                }
            });
        }
    }
}
exports.ComponentService = ComponentService;
